import React from 'react'
import { Container } from 'react-bootstrap'
import { ClockFill, ArrowRight } from 'react-bootstrap-icons'
import '../../styles/library.css'
import library1 from '../../images/campus/library/IMG_20210819_111952_971.jpg'
import library2 from '../../images/campus/library/IMG_20211223_085835_338.jpg'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../Head';




function Library() {

    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
      }, []);

    return (
        <div className='overflow-hidden '>
            <Head title="Library"/>    
            <header className="sectionHeader halfBorder mb-5 libraryHeader" as="h1">Library</header>

            <Container fluid>
                <section className='libraryQuate d-flex justify-content-center align-items-center'>
                    <div className='' >
                        <h1 data-aos="zoom-in">Libraries store the energy that fuels the imagination. They open up windows to the world and  <br />
                        inspire us to explore, achieve and contribute to improving our quality of life.</h1>
                    </div>
                </section>
            </Container>

            <Container className="px-5" >
                <section className="libraryContent" >
                    <h1 className='sectionHeader halfBorder mb-5'>Features</h1>
                    <div className=''>
                        <div className='libraryFeatures '>
                            <ul data-aos="fade-up">
                                <li>The college has adequate library facility with large number of books of different titles and publications.</li>
                                <li>NEET/IIT-JEE materials from reputed institutions are also available for students reference.</li>
                                <li>The library with its modern collection knowledge resources and innovative information services fills an essential role for students and faculty in their intellectual pursuits. </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <h1 className='sectionHeader halfBorder mb-5'>Timings</h1>
                        <div className='d-flex justify-content-around  mt-5 mb-5'  >
                            <div className='text-center' data-aos="fade-right" data-aos-duration='1000'>
                                <ClockFill size={50} color='#f04021' className='mb-4' />
                                <p className='fs-2 fw-bolder'>9 AM</p>
                            </div>

                            <div data-aos="fade-right" data-aos-duration='1300'><ArrowRight size={50} color='#f04021' className='mb-4' /></div>

                            <span className='pt-5 pt-lg-3 fs-1 fw-bolder' data-aos="fade-right" data-aos-duration='1500'>To</span>

                            <div data-aos="fade-right" data-aos-duration='1800'><ArrowRight size={50} color='#f04021'className='mb-4' /></div>

                            <div className='text-center' data-aos="fade-right" data-aos-duration='2000'>
                                <ClockFill size={50} color='#f04021' className='mb-4' />
                                <p className='fs-2 fw-bolder'>6 PM</p>
                            </div>
                        </div>

                    </div>
                    
                    <div className="activities mt-5 ">
                        <h1 className='sectionHeader halfBorder mb-5'>Library Service</h1>
                        <ul data-aos="zoom-in" >
                            <li>Circulation</li>
                            <li>Reference</li>
                            <li>Issue of old Question papers</li>
                            <li>Isssue of Magazines</li>
                        </ul>

                    </div>
                </section>
            </Container>

            <Container fluid>
                <section className='libraryGallery'>
                    <div className='d-flex '>
                        <img className='libraryPic' src={library1} alt="library" />
                        <img className='libraryPic' src={library2} alt="library" />
                    </div>
                </section>
            </Container>

        </div>
    )
}

export default Library